<div class="row">
  <div class="col-12">
    <div class="table-responsive">
      <table class="table table-bordered" aria-label="Data table">
        <thead class="thead-light">
          <tr>
            <th scope="col" *ngFor="let header of headers" (click)="header.enableSort ? sortTable(header.key) : null">
              {{header?.title}}
              <ng-container *ngIf="header?.enableSort">
                <ng-container [ngSwitch]="header?.sortType">
                  <i class="fa fa-sort-asc cursor-pointer" *ngSwitchCase="'asc'" aria-hidden="true"></i>
                  <i class="fa fa-sort-desc cursor-pointer" *ngSwitchCase="'desc'" aria-hidden="true"></i>
                  <i class="fa fa-sort cursor-pointer" *ngSwitchDefault aria-hidden="true"></i>
                </ng-container>
              </ng-container>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let d of data.data; let i = index">
            <ng-template [ngTemplateOutlet]="rowTemplate" [ngTemplateOutletContext]="{$implicit: d, index: i}">
            </ng-template>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div *ngIf="data.data?.length === 0" class="no-data" [innerHTML]="emptyDataMessage"></div>
    <div *ngIf="data?.data === null" class="no-data">
      <i class="fa fa-spinner fa-lg fa-spin" aria-hidden="true"></i>
    </div>
    <div class="d-flex flex-row justify-content-center w-100">
      <pagination [totalItems]="data.totalCount" *ngIf="data.totalCount > data.limit || displayPagination"
        [itemsPerPage]="data.limit" [(ngModel)]="data.page" (pageChanged)="pageChanged($event)" [maxSize]="5"
        [rotate]="true"></pagination>
    </div>
  </div>
</div>
