<app-menu-sidebar class="sidebar-secondary-light"></app-menu-sidebar>

<div class="content-wrapper bg-white px-3">
  <button class="btn bg-white d-block d-lg-none" (click)="onToggleMenuSidebar()">
    <img src="assets/img/icon/toggle-{{menuCollapsed ? 'expand' : 'collapse'}}.svg" alt="Toggle" width="16px">
  </button>
  <router-outlet></router-outlet>
</div>
<div id="sidebar-overlay" (click)="onToggleMenuSidebar()">
</div>
