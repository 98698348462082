<div class="row border rounded mb-6">
  <div class="col-12">
    <div class="row no-gutters card-location">
      <div class="col d-flex flex-row align-items-center">
        <div class="card-icon blue mr-3">
          <img src="assets/img/icon/person.svg" height="24px" alt="Customer">
        </div>
        <div class="d-flex flex-column">
          <span class="text-muted">Customer Name</span>
          <h4 class="card-title">{{user?.name}}</h4>
        </div>
      </div>
      <div class="col d-flex flex-row align-items-center border-left">
        <div class="card-icon orange mr-3 ml-4">
          <img src="assets/img/icon/office.svg" height="24px" alt="Customer">
        </div>
        <div class="d-flex flex-column">
          <span class="text-muted">Jumlah neuCentrix</span>
          <h4 class="card-title">{{user?.customerProfile?.count_location || 0}} Sites</h4>
        </div>
      </div>
    </div>
  </div>
</div>
<hr>
