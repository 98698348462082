import { EventTypes } from '@/shared/enum/event-type';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {
  @Output() disposeEvent = new EventEmitter();
  @Input() type!: EventTypes;
  @Input() title!: string;
  @Input() message!: string;
  show: boolean = true;

  ngOnInit(): void {
    setTimeout(() => {
      this.hide();
    }, 3000);
  }

  hide() {
    this.disposeEvent.emit();
    this.show = false;
  }
}
