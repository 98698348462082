import { ITableHeader, ITableData, ISortTable, IPaginationOutput } from '@/shared/interface/data-table.interface';
import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent {
  @Input() headers: ITableHeader[] = [];
  @Input() emptyDataMessage: string = "Data kosong";
  @Input() data: ITableData<any> = {
    data: undefined,
    totalCount: 0,
    page: 0,
    limit: 10,
  };
  @Input() displayPagination: boolean;

  @Output() sortedColumn: EventEmitter<ISortTable> = new EventEmitter();
  @Output() page: EventEmitter<number> = new EventEmitter();

  @ContentChild("row")
  rowTemplate: TemplateRef<any>;

  constructor() {
    // this is intentional
  }

  sortTable(key: string) {
    const findColumn = this.headers.find((h) => h.key === key);
    if (findColumn && findColumn.enableSort) {
      if (findColumn.sortType === "asc") {
        this.sortedColumn.emit({
          key,
          isAscending: false,
        });
        findColumn.sortType = "desc";
      } else {
        this.sortedColumn.emit({
          key,
          isAscending: true,
        });
        findColumn.sortType = "asc";
      }
      this.setSortType(findColumn);
    }
  }

  setSortType(selectedColumn: ITableHeader) {
    this.headers.forEach((h) => {
      if (h.key !== selectedColumn.key) {
        h.sortType = "unset";
      }
    });
  }

  pageChanged(page: IPaginationOutput) {
    this.page.emit(page.page);
  }
}
