<div class="brand-link d-flex flex-row align-items-center">
  <!-- Brand Logo -->
  <a [routerLink]="['/main']" class="text-center text-white">
    <img *ngIf="menuCollapsed" src="assets/img/logo.svg" alt="SDWAN Logo" class="brand-image mt-1" width="30px"
      height="28px" />
    <span class="brand-text">SDWAN</span>
  </a>
  <button class="btn bg-white p-2" (click)="onToggleMenuSidebar()">
    <img src="assets/img/icon/toggle-{{menuCollapsed ? 'expand' : 'collapse'}}.svg" alt="Toggle" width="16px"
      height="16px">
  </button>
</div>

<!-- Sidebar -->
<div class="sidebar d-flex flex-column align-items-center">
  <!-- Sidebar Menu -->
  <nav class="mt-2" style="overflow-y: hidden">
    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
      <ng-container *ngFor="let item of menu">
        <app-menu-item [menuItem]="item" *ngIf="displayMenu(item.roles)"></app-menu-item>
      </ng-container>
    </ul>
  </nav>

  <div class="sidebar-footer mt-auto mb-3 w-100">
    <!-- Sidebar user (optional) -->
    <div class="user-panel d-flex flex-row align-items-center">
      <div class="image">
        <img [src]="user?.picture" onerror="this.src='assets/img/default-profile.png'" alt="User Image" width="28px"
          height="28px">
      </div>
      <div class="info">
        {{ user?.email }}
      </div>
    </div>
    <img src="assets/img/icon/logout.svg" width="24px" alt="logout" role="button" (click)="logout()" class="logout">
  </div>
</div>
