import {
  Component,
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';

export type ConfirmType = 'Confirm' | 'Cancel';

@Component({
  selector: 'app-confirm-modal-dialog',
  template: `
    <div
      class="modal-body d-flex flex-column justify-content-center align-items-center"
    >
      <img [src]="iconImage" width="60px" height="60px" alt="" />
      <p class="title">{{ title }}</p>
      <p class="disclaimer">{{ disclaimerText }}</p>
    </div>
    <div class="modal-footer border-top-0 row">
      <button
        type="button"
        class="btn btn-cancel col"
        (click)="closeModal('Cancel')"
      >
        Batal
      </button>
      <button
        type="button"
        class="btn btn-primary col"
        (click)="closeModal('Confirm')"
      >
        {{confirmBtnText}}
      </button>
    </div>
  `,
  styleUrls: ['./action-item.component.scss']
})
// tslint:disable-next-line: component-class-suffix
export class ConfirmModalDialogComponent {
  disclaimerText: string;
  iconImage: string;
  title: string;
  confirmBtnText: string;

  constructor(private activeModal: BsModalService) {}

  closeModal(isDelete: ConfirmType) {
    this.activeModal.setDismissReason(isDelete);
    this.activeModal.hide();
  }
}

@Directive({
  selector: '[appActionItem]'
})
export class ActionItemDirective {
  @Input() iconImage: string = 'assets/img/icon/alert.svg';
  @Input() title: string = 'Apakah Anda yakin?';
  @Input() disclaimerText: string =
    'Tindakan ini bersifat permanen dan tidak dapat dibatalkan.';
  @Input() confirmBtnText: string = 'Ya';
  @Output() confirm: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('click') onClick() {
    this.openDialog();
  }

  constructor(private modalService: BsModalService) {}

  openDialog() {
    const modalRef = this.modalService.show(ConfirmModalDialogComponent, {
      keyboard: false,
      backdrop: 'static',
      class: 'center modal-delete'
    });
    modalRef.content.disclaimerText = this.disclaimerText;
    modalRef.content.title = this.title;
    modalRef.content.iconImage = this.iconImage;
    modalRef.content.confirmBtnText = this.confirmBtnText;
    modalRef.onHidden.subscribe((res: ConfirmType) => {
      if (res === 'Confirm') {
        this.confirm.emit(true);
      } else {
        this.confirm.emit(false);
      }
    });
  }
}
