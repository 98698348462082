import {AppState} from '@/store/state';
import {ToggleSidebarMenu} from '@/store/ui/actions';
import {UiState} from '@/store/ui/state';
import {Component, HostBinding, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppService} from '@services/app.service';
import {Observable} from 'rxjs';
import {MENU} from './nav.resource';
import { User } from '@/shared/interface/profile.interface';
import { ROLE } from '@/shared/enum/role';
import { INav } from './nav.interface';

const BASE_CLASSES = 'main-sidebar elevation-4';
@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
  @HostBinding('class') classes: string = BASE_CLASSES;
  public ui: Observable<UiState>;
  public menuCollapsed: boolean;
  public user: User;
  public menu: INav[] = MENU;

  constructor(public appService: AppService, private store: Store<AppState>) {}

  ngOnInit() {
    this.ui = this.store.select('ui');
    this.ui.subscribe((state: UiState) => {
      this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
      this.menuCollapsed = state.menuSidebarCollapsed;
    });
    this.user = this.appService.user;
  }

  onToggleMenuSidebar() {
    this.store.dispatch(new ToggleSidebarMenu());
  }

  logout() {
    this.appService.logout();
  }

  displayMenu(menuRoles: ROLE[]): boolean {
    return menuRoles.includes(this.user.role[0]);
  }
}
