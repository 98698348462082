import { EventTypes } from '@/shared/enum/event-type';
import { ToastEvent, ToastParam } from '@/shared/interface/toast.interface';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toastEvents: Observable<ToastEvent>;
  private _toastEvents = new Subject<ToastEvent>();

  constructor() {
    this.toastEvents = this._toastEvents.asObservable();
  }

  success(param: ToastParam) {
    this._toastEvents.next({
      message: param?.message || '',
      title: param?.title || 'Berhasil',
      type: EventTypes.Success
    })
  }

  error(param: ToastParam) {
    this._toastEvents.next({
      message: param?.message || '',
      title: param?.title || 'Gagal',
      type: EventTypes.Error
    })
  }

  warning(param: ToastParam) {
    this._toastEvents.next({
      message: param?.message || '',
      title: param?.title || 'Peringatan',
      type: EventTypes.Warning
    })
  }

  info(param: ToastParam) {
    this._toastEvents.next({
      message: param?.message || '',
      title: param?.title || 'Info',
      type: EventTypes.Info
    })
  }
}
