import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from '@/app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from '@modules/login/login.component';
import { FooterComponent } from '@modules/main/footer/footer.component';
import { MainComponent } from '@modules/main/main.component';
import { MenuSidebarComponent } from '@modules/main/menu-sidebar/menu-sidebar.component';
import { BlankComponent } from '@pages/blank/blank.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { ProfileComponent } from '@pages/profile/profile.component';
import { AppComponent } from './app.component';

import { CommonModule, registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { ForgotPasswordComponent } from '@modules/forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from '@modules/recover-password/recover-password.component';
import { StoreModule } from '@ngrx/store';
import { ProfabricComponentsModule } from '@profabric/angular-components';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { SidebarSearchComponent } from './components/sidebar-search/sidebar-search.component';
import { ControlSidebarComponent } from './modules/main/control-sidebar/control-sidebar.component';
import { MainMenuComponent } from './pages/main-menu/main-menu.component';
import { SubMenuComponent } from './pages/main-menu/sub-menu/sub-menu.component';
import { errorInterceptor } from './shared/interceptors/http-error-interceptor';
import { LoadingInterceptor } from './shared/interceptors/loading.interceptor';
import { tokenInterceptor } from './shared/interceptors/token.interceptor';
import { SharedModule } from './shared/shared.module';
import { authReducer } from './store/auth/reducer';
import { uiReducer } from './store/ui/reducer';

registerLocaleData(localeEn, 'en-EN');

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    FooterComponent,
    MenuSidebarComponent,
    BlankComponent,
    ProfileComponent,
    DashboardComponent,
    ForgotPasswordComponent,
    RecoverPasswordComponent,
    MainMenuComponent,
    SubMenuComponent,
    MenuItemComponent,
    ControlSidebarComponent,
    SidebarSearchComponent
  ],
  imports: [
    ProfabricComponentsModule,
    CommonModule,
    BrowserModule,
    StoreModule.forRoot({auth: authReducer, ui: uiReducer}),
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    SharedModule,
  ],
  providers: [
    provideHttpClient(
      withInterceptors([tokenInterceptor, LoadingInterceptor, errorInterceptor])
    )
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
