import { API_SDWAN, LOGIN } from '@/shared/const/path-api.const';
import { ROLE } from '@/shared/enum/role';
import {
  ICustomerProfileResponse,
  ILogin,
  IProfile,
  IProfileResponse,
  JwtData,
  User
} from '@/shared/interface/profile.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { Observable, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public user: User = null;

  constructor(
    private router: Router,
    private http: HttpClient
  ) {}

  async loginByAuth(data: ILogin) {
    try {
      const login$ = this.http.post(`${LOGIN}`, data);
      const dataLogin: IProfileResponse = (await lastValueFrom(
        login$
      )) as IProfileResponse;
      localStorage.setItem('token', dataLogin.data.authorization.token);
      await this.setProfile(dataLogin.data);
      await this.getProfile();
      this.redirectToMenu(this.user.role[0]);
    } catch (error) {
      throw(error);
    }
  }

  async setProfile(profile: IProfile) {
    const decodeJwt = jwtDecode(profile.authorization.token) as JwtData;
    this.user = {
      ...profile.user,
      role: decodeJwt.role
    };
    if (decodeJwt.role[0] === ROLE.CUSTOMER) {
      await this.setCustomerProfile();
    } else {
      localStorage.setItem('user', JSON.stringify(this.user));
    }
  }

  async setCustomerProfile() {
    const customerProfile: ICustomerProfileResponse = await lastValueFrom(this.getCustomerProfile());
    this.user.customerProfile = customerProfile.data;
    localStorage.setItem('user', JSON.stringify(this.user));
  }

  getProfile() {
    try {
      const user = localStorage.getItem('user');
      this.user = JSON.parse(user);
    } catch (error) {
      this.logout();
      throw error;
    }
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.user = null;
    this.router.navigate(['/']);
  }

  redirectToMenu(role: ROLE) {
    switch (role) {
      case ROLE.ACCOUNT_MANAGER:
      case ROLE.SERVICE_MANAGER:
        this.router.navigate(['/main/order/list']);
        break;
      case ROLE.CUSTOMER:
        this.router.navigate(['/main']);
        break;
      case ROLE.ASSURANCE_MANAGER:
        this.router.navigate(['/main/assurance']);
        break;
      default:
        break;
    }
  }

  getCustomerProfile(): Observable<ICustomerProfileResponse> {
    return this.http.get<ICustomerProfileResponse>(`${API_SDWAN}/profile`);
  }
}
