import {ROLE} from '@/shared/enum/role';
import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {AppService} from '@services/app.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MainPageGuard implements CanActivate {
  constructor(private router: Router, private appService: AppService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const role = this.appService.user.role[0];

    switch (role) {
      case ROLE.ACCOUNT_MANAGER:
      case ROLE.SERVICE_MANAGER:
        this.router.navigate(['/main/order/list']);
        return false;
      case ROLE.CUSTOMER:
        return true;
      case ROLE.ASSURANCE_MANAGER:
        this.router.navigate(['/main/assurance']);
        return false;
      default:
        this.router.navigate(['/home']);
        return false;
    }
  }
}
