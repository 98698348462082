<div class="container-fluid p-3 vh-100">
  <div class="row h-100">
    <div class="col-md-5 d-none d-md-flex login-image flex-column justify-content-between">
      <h1 class="title text-white">Berikan Yang Terbaik, untuk Pelanggan Terbaik</h1>
      <img src="assets/img/logo-alphabet.svg" class="ml-auto" alt="SDWAN" height="28px">
    </div>
    <div class="col-md-5 d-flex flex-column justify-content-center login-form--container mx-auto">
      <h2>Selamat Datang di SDWAN</h2>
      <p class="description">Masuk dan kelola data centermu menggunakan neuCentrIX</p>
      <form [formGroup]="loginForm" (ngSubmit)="loginByAuth()" class="mt-5">
        <div class="form-group">
          <label for="email">Email</label>
          <input formControlName="email" type="email" class="form-control" placeholder="Email" />
          <div class="invalid-feedback">
            <p *ngIf="loginForm.get('email')?.errors?.required">*Email Wajib diisi</p>
            <p *ngIf="loginForm.get('email')?.errors?.email">*Email tidak valid</p>
          </div>
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <div class="input-group mb-3">
            <input formControlName="password" [type]="isViewPassword ? 'text' : 'password'"
              class="form-control border-right-0" placeholder="Password" />
            <div class="input-group-append" (click)="isViewPassword = !isViewPassword">
              <div class="input-group-text border-left-0 bg-white">
                <img *ngIf="!loginForm.get('password')?.errors; else errorIcon" src="assets/img/icon/eye.svg"
                  width="20px">
                <ng-template #errorIcon>
                  <img src="assets/img/icon/eye-error.svg" width="20px">
                </ng-template>
              </div>
            </div>
            <div class="invalid-feedback">
              <p *ngIf="loginForm.get('password')?.errors?.required">*Password Wajib diisi</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button type="submit" class="btn w-100" [ngClass]="loginForm.invalid ? 'btn-disabled' : 'btn-primary'"
              [disabled]="loginForm.invalid || isAuthLoading">{{isAuthLoading ? 'Loading...' : 'Login'}}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
