import {User} from '@/shared/interface/profile.interface';
import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AppService} from '@services/app.service';

@Component({
  selector: 'app-overview-card',
  templateUrl: './overview-card.component.html',
  styleUrls: ['./overview-card.component.scss']
})
export class OverviewCardComponent implements OnChanges {
  @Input() refresh: boolean;

  user: User;

  constructor(private appService: AppService) {
    this.user = this.appService.user;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.refresh.currentValue) {
      this.getProfile();
    }
  }

  getProfile() {
    this.appService.setCustomerProfile();
  }
}
