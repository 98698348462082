import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AppService } from '@services/app.service';
import { ToastService } from '@services/toast.service';
import { catchError } from 'rxjs';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const toast = inject(ToastService);
  const appService = inject(AppService);
  return next(req).pipe(
    catchError((err) => {
      if (err?.status === 401 && !req.url.includes("/login")) {
        appService.logout();
      }
      toast.error({
        message: err?.error?.message || 'Silakan coba beberapa saat lagi.',
        title: translateHttpResponseCode(err)
      });
      throw err;
    })
  );
};

function translateHttpResponseCode(error: HttpErrorResponse) {

  switch (error?.status) {
    case 401:
      return 'Anda tidak memiliki akses untuk halaman ini';
    case 400:
      return 'Data tidak valid';
    case 404:
      return 'Data tidak ditemukan';
    case 500:
      return 'Internal Server Error';
    case 503:
      return 'Sistem tidak tersedia';
    default:
      return 'Gagal memproses permintaan Anda';
  }
}
