import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@guards/auth.guard';
import {MainPageGuard} from '@guards/main-page.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {LoginComponent} from '@modules/login/login.component';
import {MainComponent} from '@modules/main/main.component';

const routes: Routes = [
  {
    path: 'main',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'location',
        canActivate: [MainPageGuard],
        loadChildren: () =>
          import('./modules/location/location.module').then(
            (m) => m.LocationModule
          )
      },
      {
        path: 'order',
        loadChildren: () =>
          import('./modules/order/order.module').then((m) => m.OrderModule)
      },
      {
        path: 'assurance',
        loadChildren: () =>
          import('./modules/assurance/assurance.module').then(
            (m) => m.AssuranceModule
          )
      },
      {
        path: 'user-management',
        loadChildren: () =>
          import('./modules/user-management/user-management.module').then(
            (m) => m.UserManagementModule
          )
      },
      {
        path: '',
        redirectTo: 'location',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./landing-page/landing-page.module').then(
        (m) => m.LandingPageModule
      )
  },
  {
    path: '',
    redirectTo: '/home#home',
    pathMatch: 'full'
  },
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollOffset: [0, 50]
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
