<div class="toaster px-3 py-2" *ngIf="currentToast?.length > 0">
  <ng-container *ngFor="let toast of currentToast; index as i">
    <app-toast
      [message]="toast.message"
      [title]="toast.title"
      [type]="toast.type"
      (disposeEvent)="dispose(i)"
    ></app-toast>
  </ng-container>
</div>
