import { ILogin } from '@/shared/interface/profile.interface';
import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AppService} from '@services/app.service';
import { ToastService } from '@services/toast.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm: UntypedFormGroup;
  public isAuthLoading = false;
  public isGoogleLoading = false;
  public isFacebookLoading = false;
  isViewPassword: boolean;

  constructor(private toastr: ToastService, private appService: AppService) {}

  ngOnInit() {
    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl(null, [
        Validators.required,
        Validators.email
      ]),
      password: new UntypedFormControl(null, Validators.required)
    });
  }

  async loginByAuth() {
    try {
      if (this.loginForm.valid) {
        this.isAuthLoading = true;
        const formValue = this.loginForm.value;
        const payload: ILogin = {
          email: formValue.email,
          password: formValue.password,
          application_id: 1
        }
        await this.appService.loginByAuth(payload);
        this.isAuthLoading = false;
      } else {
        this.toastr.error({message: 'Form is not valid!'});
      }
    } catch (error) {
      this.isAuthLoading = false;
    }
  }
}
