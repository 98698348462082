import {JwtData} from '@/shared/interface/profile.interface';
import {Injectable} from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  Route,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import {jwtDecode} from 'jwt-decode';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NonAuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!localStorage.getItem('token')) {
      return true;
    }
    const decodedJwt = jwtDecode(localStorage.getItem('token')) as JwtData;
    const expiryToken = new Date(+`${decodedJwt.exp}000`);
    if (new Date() >= expiryToken) {
      return true;
    }
    this.router.navigate(['/main']);
    return false;
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate(next, state);
  }
}
