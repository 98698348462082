import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { DataTableComponent } from './components/data-table/data-table.component';
import { OverviewCardComponent } from './components/overview-card/overview-card.component';
import { ActionItemDirective, ConfirmModalDialogComponent } from './directives/action-item.directive';
import { ToasterComponent } from './components/toaster/toaster.component';
import { ToastComponent } from './components/toast/toast.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TooltipModule } from 'ngx-bootstrap/tooltip';


@NgModule({
  declarations: [
    DataTableComponent,
    ActionItemDirective,
    OverviewCardComponent,
    ToasterComponent,
    ToastComponent,
    ConfirmModalDialogComponent
  ],
  imports: [
    CommonModule,
    PaginationModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    NgSelectModule,
    InfiniteScrollModule,
    TooltipModule.forRoot()
  ],
  exports: [
    DataTableComponent,
    ActionItemDirective,
    ModalModule,
    ReactiveFormsModule,
    NgSelectModule,
    FormsModule,
    OverviewCardComponent,
    ToasterComponent,
    InfiniteScrollModule,
    ConfirmModalDialogComponent,
    TooltipModule
  ],
  entryComponents: [
    ConfirmModalDialogComponent
  ]
})
export class SharedModule { }
