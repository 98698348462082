import { environment } from "environments/environment";

const API_URL = environment.apiUrl;
export const API_SDWAN = `${API_URL}/api/sdwan`;
export const MASTER_DATA = `${API_SDWAN}/master`;

export const LOGIN = `${API_URL}/api/login`;
export const ACCOUNT_MANAGER = `${API_SDWAN}/am`;
export const SERVICE_MANAGER = `${API_SDWAN}/sm`;
export const ASSURANCE_MANAGER = `${API_SDWAN}/asm`;

export const REGISTER_USER = `${ACCOUNT_MANAGER}/register`;
export const USER_MANAGEMENT = `${ACCOUNT_MANAGER}/user`;

export const LOCATION = `${API_SDWAN}/location`;

export const HISTORY_ORDER = `${API_SDWAN}/history`;
export const ORDER = `${API_SDWAN}/order`;
export const AM_ORDER = `${ACCOUNT_MANAGER}/order`;

export const ASSURANCE = `/assurance`;

export const FILE_SERVICE = '/file-service';
export const DOWNLOAD = '/download';
