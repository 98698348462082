import {ToastEvent} from '@/shared/interface/toast.interface';
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ToastService} from '@services/toast.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss']
})
export class ToasterComponent implements OnInit, OnDestroy {
  currentToast: ToastEvent[] = [];
  subscription: Subscription;

  constructor(
    private toastService: ToastService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscribeToToast();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  subscribeToToast() {
    this.subscription = this.toastService.toastEvents.subscribe((toasts) => {
      const currentToast: ToastEvent = {
        message: toasts.message,
        title: toasts.title,
        type: toasts.type
      };
      this.currentToast.push(currentToast);
      this.cdr.detectChanges();
    });
  }

  dispose(index: number) {
    this.currentToast.splice(index, 1);
    this.cdr.detectChanges();
  }
}
