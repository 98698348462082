import {ROLE} from '@/shared/enum/role';
import {INav} from './nav.interface';

export const MENU: INav[] = [
  {
    name: 'Lokasi Neucentrix',
    roles: [ROLE.CUSTOMER],
    iconClasses: 'assets/img/icon/map-marker.svg',
    path: ['/main/location']
  },
  {
    name: 'Transaction',
    roles: [ROLE.CUSTOMER],
    iconClasses: 'assets/img/icon/order.svg',
    children: [
      {
        name: 'Order',
        roles: [ROLE.CUSTOMER],
        iconClasses: 'assets/img/icon/dot.svg',
        path: ['/main/order']
      },
      {
        name: 'History',
        roles: [ROLE.CUSTOMER],
        iconClasses: 'assets/img/icon/dot.svg',
        path: ['/main/order/history']
      }
    ]
  },
  {
    name: 'Order',
    roles: [ROLE.ACCOUNT_MANAGER, ROLE.SERVICE_MANAGER],
    iconClasses: 'assets/img/icon/order.svg',
    children: [],
    path: ['/main/order/list']
  },
  {
    name: 'Assurance',
    roles: [ROLE.ACCOUNT_MANAGER, ROLE.CUSTOMER, ROLE.ASSURANCE_MANAGER],
    iconClasses: 'assets/img/icon/assurance.svg',
    path: ['/main/assurance']
  },
  {
    name: 'User Management',
    roles: [ROLE.ACCOUNT_MANAGER],
    iconClasses: 'assets/img/icon/users.svg',
    path: ['/main/user-management']
  }
];
